<template>
    <div id="loginPage">
        <login1 v-if="system1"></login1>
        <login2 v-if="system2"></login2>
        <login3 v-if="system3"></login3>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import login1 from "./login_item/login1.vue"; //文物保护监测预警平台
import login2 from "./login_item/login2.vue"; //普洱景迈古茶林文化景观遗产林业监测
import login3 from "./login_item/login3.vue"; //普洱景迈山古茶林文化景观遗产之茶特中心
export default {
    name: "login",
    components: {
        login1,
        login2,
        login3,
    },
    computed: {
        ...mapGetters(["system"]),
        system1() {
            return this.system === "system1";
        },
        system2() {
            return this.system === "system2";
        },
        system3() {
            return this.system === "system3";
        },
    },
};
</script>
<style lang="scss">
#loginPage {
    width: 100%;
    height: 100%;
}
</style>
