//创建连接对象connection
// 数据推送
const signalr_connection = new signalR.HubConnectionBuilder()
    .withUrl(window.SINGNALR_CONNECT)
    .configureLogging(signalR.LogLevel.Information)
    .build();

//启动connection
signalr_connection.start()
    .then(function () {
        console.log("连接成功");
    }).catch(function (ex) {
        //SignalR JavaScript 客户端不会自动重新连接，
        //必须编写代码将手动重新连接你的客户端
        // setTimeout(() => start(), 5000);
        setTimeout(function () {
            start();
        }, 5000)
    });

signalr_connection.onclose(function () {
    start();
});


//接收
function start() {
    try {
        signalr_connection.start();
    } catch (err) {
        setTimeout(function () {
            start();
        }, 5000)
    }
}

//绑定事件("ReceiveMessage"和服务器端的SendMessage方法中的第一个参数一致)
window.signalr_connection = signalr_connection
// window.signalr_connection.on("XX", function(data) {
//     console.log("二氧化碳-监听", data)
// })