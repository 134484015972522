<template>
    <div class="login-checkcode" @click="changeCheckcode">
        <img :src="picture" alt="" />
    </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
    name: "login-checkcode",

    /* 使用 props传递数据 */
    props: {
        width: {
            type: Number,
            default: 100,
        },
        height: {
            type: Number,
            default: 40,
        },
    },
    /* 组件数据对象 */
    data: function () {
        return {
            resetVarifyTimer: null, // 每隔55秒切换验证码的计时器
            length: 4, // 随机验证码的长度
            picture: null, // base6图片 字符串
        };
    },
    mounted() {
        this.changeCheckcode();
    },
    destroyed() {
        clearTimeout(this.resetVarifyTimer);
    },
    /* 添加组件方法 */
    methods: {
        ...mapActions(["GenerateRandomCodePicutreByBitMap"]),
        // 生成验证码
        async changeCheckcode() {
            const length = this.length;
            let res = await this.GenerateRandomCodePicutreByBitMap({ length });
            if (res.ISSUCCESS) {
                const { ID, PICTURE } = res.RESULTVALUE;
                this.picture = `data:image/png;base64,${PICTURE}`;
                this.$emit("changed", ID);
            }
            clearTimeout(this.resetVarifyTimer);
            this.resetVarifyTimer = setTimeout(async () => {
                await this.changeCheckcode();
            }, 55000);
        },
    },
};
</script>
<style scoped>
.login-checkcode {
    height: 40px;
    width: 110px;
}
</style>
