
import * as TYPES from "@store/modules/mutation-type.js";
import { mapActions, mapMutations, mapGetters } from "vuex";
import md5 from "js-md5";
export default {
    name: "",
    components: {
    },
    data() {
        return {
            dialogData: {
                title: "忘记密码",
                width: "500px",
                action: true,
                dialog: false,
            },
            dialogForget: {
                userName: "",
                passWord: "",
                repeatPassword: "",
                code: "",
                phoneNumber: "",
            },
            dialogForgetRules: {
                userName: [
                    {
                        required: true,
                        message: "请输入用户名",
                        trigger: "blur",
                    },
                ],
                phoneNumber: [
                    {
                        required: true,
                        message: "请输入手机号",
                        trigger: "blur",
                    },
                    {
                        pattern: /^1[3|4|5|7|8][0-9]{9}$/,
                        message: "格式错误",
                    },
                ],
                code: [
                    {
                        required: true,
                        message: "请输入验证码",
                        trigger: "blur",
                    },
                ],
                passWord: [
                    {
                        required: true,
                        message: "请输入新密码",
                        trigger: "blur",
                    },
                ],
                repeatPassword: [
                    {
                        required: true,
                        message: "请输入确认密码",
                        trigger: "blur",
                    },
                ],
            },
            disabledGetCodeBtn: true,
            getCodeBtnTxt: "获取手机验证码",
            getCodeCountDownTimer: null,
        };
    },
    computed: {
        ...mapGetters([
            "system"
        ]),
    },
    mounted() {
    },
    methods: {
        ...mapActions([
            "getMessageCode",
            "forgetPWD",
        ]),
        // 忘记密码
        onSubmit() {
            this.$refs.dialogForget.validate((valid) => {
                if (valid) {
                    if (
                        this.dialogForget.passWord ==
                        this.dialogForget.repeatPassword
                    ) {
                        this.onSubmitUp();
                    } else {
                        this.common.showMsg("两次输入密码不一致", "warning");
                    }
                } else {
                    return false;
                }
            });
        },
        async onSubmitUp() {
            let result = await this.forgetPWD({
                username: this.dialogForget.userName,
                phonenumber: this.dialogForget.phoneNumber,
                newPwd: md5(this.dialogForget.repeatPassword),
                code: this.dialogForget.code,
            });
            if (result.ISSUCCESS) {
                this.common.showMsg(result.RESULTDESCRIPTION, "success");
                this.resetData();
            } else {
                this.common.showMsg(result.RESULTDESCRIPTION, "warning");
            }
        },
        resetData() {
            clearInterval(this.getCodeCountDownTimer);
            this.getCodeBtnTxt = "获取手机验证码";
            this.dialogForget.userName = "";
            this.dialogForget.phoneNumber = "";
            this.dialogForget.repeatPassword = "";
            this.dialogForget.code = "";
            this.dialogForget.password = "";
            this.dialogData.dialog = false;
        },
        // 发送验证码
        async getCodeFromPhone() {
            if (!this.dialogForget.phoneNumber) {
                this.common.showMsg("请输入手机号", "warning");
                return;
            }
            if (this.getCodeCountDownTimer) {
                return;
            }
            let countDown = 60;
            this.getCodeCountDownTimer = setInterval(() => {
                if (countDown === 0) {
                    clearInterval(this.getCodeCountDownTimer);
                    this.getCodeCountDownTimer = null;
                    this.getCodeBtnTxt = "获取手机验证码";
                    this.disabledGetCodeBtn = false;
                    return;
                }
                this.getCodeBtnTxt = `${countDown--}秒后重新获取`;
            }, 1000);
            this.disabledGetCodeBtn = true;
            let res = await this.getMessageCode({
                username: this.dialogForget.userName,
                phonenumber: this.dialogForget.phoneNumber,
            });
            if (res.IsSuccess) {
                this.common.showMsg(res.ResultDescription, "success");
            } else {
                clearInterval(this.getCodeCountDownTimer);
                this.getCodeCountDownTimer = null
                this.common.showMsg(res.ResultDescription, "warning");
            }
        },
    },

};