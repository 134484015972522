<template>
    <div id="loginPart3" class="loginPart">
        <!-- 登陆 -->
        <img src="@image/login/logo.png" class="logoPart" />
        <img src="@image/login/login3/title.png" class="title" />
        <img src="@image/login/login3/circle.png" class="circle" />
        <!-- 两种登录方式 -->
        <div class="loginTypePart">
            <loginType @getTabActiveNum="getTabActiveNum"></loginType>
        </div>
        <!-- 表单登录 -->
        <loginForm ref="loginForm" v-if="activeNum == 0"></loginForm>
        <!-- 二维码登录 -->
        <div class="codePart" v-else>
            <qrCode ref="qrCode"></qrCode>
        </div>

        <div class="bottomDesc">
            <span style="margin-right: 20px"
                >建设单位：普洱景迈山古茶林保护管理局</span
            >
            <span>技术支持：国信司南（北京）地理信息技术有限公司</span>
        </div>
        <forgetPassword ref="forgetPassword"></forgetPassword>
    </div>
</template>
<script>
import loginForm from "../comp/login_form3.vue";
import forgetPassword from "../comp/forget_password.vue";
import loginType from "../login_type/login_type.vue";
import qrCode from "../login_type/qr_code.vue";
export default {
    name: "login2",
    components: {
        loginForm,
        forgetPassword,
        loginType,
        qrCode,
    },
    data() {
        return {
            activeNum: 0,
        };
    },
    methods: {
        getTabActiveNum(index) {
            this.activeNum = index;
            if (index == 1) {
                this.$nextTick(() => {
                    this.$refs.qrCode.startScanCodeLogin();
                });
            }
        },
    },
};
</script>
<style lang="scss">
#loginPart3.loginPart {
    width: 100%;
    height: 100%;
    position: relative;
    background: url("~@image/login/login3/bg.png") no-repeat;
    background-size: 100% 100%;
    padding: 44px 0 0 79px;
    .loginForm {
        position: absolute;
        right: 180px;
        top: 270px;
        width: 436px;
        height: 394px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        padding: 1% 2% 1.2%;
        border-radius: 4px;
        background: #fff;
        .enterCtn {
            margin-top: 50px;
            .enterItem {
                width: 100%;
                height: 44px;
                padding-left: 24px;
                border: 2px solid rgba(243, 243, 243, 1);
                position: relative;
                background: #fff;
                &:hover {
                    &::before {
                        position: absolute;
                        left: 0;
                        top: 0;
                        content: "";
                        width: 5px;
                        height: 100%;
                        background: rgba(24, 109, 112, 1);
                        border-radius: 5px;
                    }
                }
                .icon {
                    width: 15px;
                    height: 16px;
                    margin-right: 10px;
                }
                .inputItem {
                    width: 100%;
                    height: 41px;
                }
            }
            .el-form-item__error {
                top: 40px;
            }
        }

        .loginChoice {
            margin-top: 40px;
            .choiceItem {
                color: #333;
            }

            .codeIcon {
                margin-left: 10px;
                width: 21px;
                height: 21px;
                background: url("~@image/login/code.png");
                background-size: 100% 100%;
            }
            .codeIconLoad {
                width: 13px;
                height: 21px;
                background: url("~@image/login/loadApp.png");
                background-size: 100% 100%;
            }
        }
    }

    .loginTypePart {
        position: absolute;
        right: 360px;
        top: 300px;
        z-index: 10;
    }
    .codePart {
        position: absolute;
        right: 180px;
        top: 270px;
        width: 436px;
        height: 394px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        background: #fff;
        padding-top: 20px;
    }

    .loginBtn {
        background: #305a44;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        color: #fff;
        line-height: 41px;
        border-radius: 5px;
        margin-top: 24px;
    }

    .bottomDesc {
        position: absolute;
        bottom: 4%;
        left: 50%;
        transform: translateX(-50%);
        font-size: 16px;
        color: #305a44;
    }
    .title {
        position: relative;
        top: -40px;
        left: 10px;
        width: 42%;
    }

    .circle {
        position: absolute;
        bottom: 60px;
        left: 105px;
        width: 721px;
    }
    .logoPart {
        width: 151px;
        height: 172px;
    }
}

@media screen and (max-width: 1500px) {
    #loginPart3.loginPart {
        .title {
            top: -54px;
        }
        .circle {
            width: 600px;
        }
        .bottomDesc {
            bottom: 3%;
        }
    }
}
</style>
