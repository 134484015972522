<template>
    <div id="loginPart1" class="loginPart">
        <img src="@image/login/login1/rightBg.png" class="loginRightBg" />
        <!-- 登陆 -->
        <div class="enterPart">
            <img src="@image/login/logo.png" class="logo" />
            <img src="@image/login/login1/title.png" class="title" />
            <!-- 两种登录方式 -->
            <div class="loginTypePart">
                <loginType @getTabActiveNum="getTabActiveNum"></loginType>
            </div>
            <div v-if="activeNum == 0" class="wrapLoginForm">
                <loginForm ref="loginForm"></loginForm>
            </div>

            <!-- 二维码登录 -->
            <div class="codePart" v-else>
                <qrCode ref="qrCode"></qrCode>
            </div>
        </div>

        <div class="bottomDesc">
            <span style="margin-right: 20px"
                >版权所有©普洱景迈山古茶林保护管理局</span
            >
            <span>技术支持：国信司南（北京）地理信息技术有限公司</span>
        </div>

        <forgetPassword ref="forgetPassword"></forgetPassword>
    </div>
</template>
<script>
import loginForm from "../comp/login_form.vue";
import forgetPassword from "../comp/forget_password.vue";
import loginType from "../login_type/login_type.vue";
import qrCode from "../login_type/qr_code.vue";
export default {
    name: "login1",
    components: {
        loginForm,
        forgetPassword,
        loginType,
        qrCode,
    },
    data() {
        return {
            activeNum: 0,
        };
    },
    methods: {
        getTabActiveNum(index) {
            this.activeNum = index;
            if (index == 1) {
                this.$nextTick(() => {
                    this.$refs.qrCode.startScanCodeLogin();
                });
            }
        },
    },
};
</script>
<style lang="scss">
#loginPart1 {
    width: 100%;
    height: 100%;
    position: relative;
    padding-left: 155px;
    .loginRightBg {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
    }

    .enterPart {
        width: 362px;
        height: calc(100% - 48px);
        padding-top: 48px;
        position: relative;
        .wrapLoginForm {
            margin-top: 40px;
        }
        .loginTypePart {
            position: absolute;
            left: 0;
            top: 310px;
            z-index: 10;
        }
        .codePart {
            position: absolute;
            left: -24px;
            top: 300px;
        }
        .logo {
            width: 40%;
        }
        .title {
            margin-top: 28px;
            margin-bottom: 10%;
        }
        .enterCtn {
            border: 2px solid rgba(243, 243, 243, 1);
            .logMsg {
                display: none;
            }

            .enterItem {
                width: 100%;
                height: 90px;
                padding-left: 24px;
                padding-top: 2px;
                border-bottom: 2px solid rgba(243, 243, 243, 1);
                position: relative;
                &:hover {
                    &::before {
                        position: absolute;
                        left: 0;
                        top: 0;
                        content: "";
                        width: 5px;
                        height: 100%;
                        background: rgba(24, 109, 112, 1);
                        border-radius: 5px;
                    }
                }
                .icon {
                    width: 15px;
                    height: 16px;
                    margin-right: 10px;
                }
                .inputItem {
                    width: 100%;
                    height: 41px;
                }
            }
        }

        .loginChoice {
            margin-top: 16px;
            .choiceItem {
                color: rgba(199, 199, 199, 1);
            }

            .codeIcon {
                margin-left: 10px;
                width: 21px;
                height: 21px;
                background: url("~@image/login/code.png");
                background-size: 100% 100%;
            }
            .codeIconLoad {
                width: 13px;
                height: 21px;
                background: url("~@image/login/loadApp.png");
                background-size: 100% 100%;
            }
        }

        .loginBtn {
            background: #20b595;
            font-size: 16px;
            font-weight: 700;
            text-align: center;
            color: #fff;
            line-height: 41px;
            border-radius: 5px;
            margin-top: 66px;
            margin-top: 5%;
        }
    }

    .bottomDesc {
        position: absolute;
        bottom: 20px;
        left: 155px;
        font-size: 16px;
        color: rgba(203, 203, 203, 1);
    }
}

@media screen and (max-width: 1500px) {
    #loginPart1 {
        .enterPart {
            .title {
                width: 70%;
            }
            .enterCtn {
                .enterItem {
                    height: 45px;
                }
            }
        }
    }
}
</style>
