import * as TYPES from "@store/modules/mutation-type.js";
import { mapActions, mapMutations, mapGetters } from "vuex";
import md5 from "js-md5";
export default {
    name: "",
    components: {
    },
    data() {
        return {
            tabActiveNum: 0,
            loadApp: false,
            uuid: "",
            login: {
                userName: "",
                passWord: "",
                checkCode: "",
                timer: null, // 定时器
                src: "", // 图片验证码
                guid: "", // 验证id
            },
            loginRules: {
                userName: [
                    {
                        required: true,
                        message: "请输入用户名",
                        trigger: "blur",
                    },
                ],
                passWord: [
                    {
                        required: true,
                        message: "请输入密码",
                        trigger: "blur",
                    },
                ],
                checkCode: [
                    {
                        required: true,
                        message: "请输入验证码",
                        trigger: "blur",
                    },
                ],
            },
            rememberPWD: false,
            dialogForget: {
                userName: "",
                passWord: "",
                repeatPassword: "",
                code: "",
                phoneNumber: "",
            },
            getCodeBtnTxt: "获取手机验证码",
            getCodeCountDownTimer: null,
            systemList2: [
                {
                    id: "10",
                    name: "工作台面",
                    src: require("@image/header/icon/gztm.png"),
                    routeName: "basicdata",
                },
                {
                    id: "20",
                    name: "巡查管理",
                    src: require("@image/header/icon/xcgl.png"),
                    routeName: "monitoringcloud",
                },
                {
                    id: "93",
                    name: "数据管理",
                    src: require("@image/header/icon/sjgl.png"),
                    // routeName: "data_admin",
                },
                {
                    id: "94",
                    name: "报告管理",
                    src: require("@image/header/icon/sjgl.png"),
                    routeName: "patrolReport",
                },
            ],
            systemList3: [
                {
                    id: "10",
                    name: "工作台面",
                    src: require("@image/header/icon/gztm.png"),
                    routeName: "analysis-assess",
                },
                {
                    id: "93",
                    name: "数据管理",
                    src: require("@image/header/icon/sjgl.png"),
                    // routeName: "data_admin",
                },
            ],
        };
    },
    computed: {
        ...mapGetters([
            "system"
        ]),
    },
    mounted() {
        this.getPassword();
        this.resetLocalData();
        this.loginByUrl();
    },
    methods: {
        ...mapMutations({
            SET_DATAGETXZC: TYPES.SET_DATAGETXZC,
            SET_DATAGETCMXZ: TYPES.SET_DATAGETCMXZ,
            SET_DATAGEJMBH: TYPES.SET_DATAGEJMBH,
            SET_DATAGEJMLX: TYPES.SET_DATAGEJMLX,
            SET_DATAGEDCYXM: TYPES.SET_DATAGEDCYXM,
            SET_DATAGETSJZTDCY: TYPES.SET_DATAGETSJZTDCY,
            SET_DATAGETSJZTCCY: TYPES.SET_DATAGETSJZTCCY,
            SET_DATAPAGEINDEX: TYPES.SET_DATAPAGEINDEX,
            SET_DATAGETZTACTIVENUM: TYPES.SET_DATAGETZTACTIVENUM,
            SET_USERNAME: TYPES.SET_USERNAME,
            SET_USERREALNAME: TYPES.SET_USERREALNAME,
            SET_USERID: TYPES.SET_USERID,
            SET_DEPARTMENTID: TYPES.SET_DEPARTMENTID,
            SET_MODULEPOWER: TYPES.SET_MODULEPOWER,
            SET_ROLEID: TYPES.SET_ROLEID,
            SET_ACCESSTOKEN: TYPES.SET_ACCESSTOKEN,
            SET_REFRESHTOKEN: TYPES.SET_REFRESHTOKEN,
            SET_SYS_ACCESSTOKEN: TYPES.SET_SYS_ACCESSTOKEN,
            SET_SYS_REFRESHTOKEN: TYPES.SET_SYS_REFRESHTOKEN,
            SET_REFRESHTIMER: TYPES.SET_REFRESHTIMER
        }),
        ...mapActions([
            "loginNeedCode",
            "getFuncTree",
            "checkUserName",
            "GetFuncTreeFirst",
            "getSysToken"
        ]),
        changed(guid) {
            this.login.guid = guid;
            this.randomcode = "";
        },
        resetLocalData() {
            this.SET_ACCESSTOKEN("");
            this.SET_REFRESHTOKEN("");
            this.SET_SYS_ACCESSTOKEN("");
            this.SET_SYS_REFRESHTOKEN("");
        },
        // 登录提交
        loginUp() {
            let str = ""
            if (!this.login.userName) {
                str = "请输入用户名"
            } else if (!this.login.passWord) {
                str = "请输入密码"
            } else if (!this.login.checkCode) {
                str = "请输入验证码"
            }
            if (str) {
                this.common.showMsg(str, "warning")
            } else {
                this.loginUpAction();
            }
        },
        async loginUpAction() {
            let result = await this.loginNeedCode({
                userName: this.login.userName,
                pwd: md5(this.login.passWord),
                imageCode: this.login.checkCode,
                guid: this.login.guid,
            });
            if (result.ISSUCCESS) {
                this.SET_DATAGETXZC("");
                this.SET_DATAGETCMXZ("");
                this.SET_DATAGEJMBH("");
                this.SET_DATAGEJMLX("");
                this.SET_DATAGEDCYXM("");
                this.SET_DATAGETSJZTDCY([]);
                this.SET_DATAGETSJZTCCY([]);
                this.SET_DATAPAGEINDEX(1);
                this.SET_DATAGETZTACTIVENUM(0);
                this.saveData(result.RESULTVALUE);
            } else {
                this.login.checkCode = "";
                this.$refs.loginNeedCode.changeCheckcode();
                this.common.showMsg(result.RESULTDESCRIPTION, "warning");
            }
        },
        // 储存数据 获得用户的功能权限
        async saveData(logInfo) {
            if (
                logInfo.DOUBLETOKEN &&
                logInfo.DOUBLETOKEN.ACCESSTOKEN &&
                logInfo.DOUBLETOKEN.ACCESSTOKEN.TOKENCONTENT
            ) {
                this.SET_ACCESSTOKEN(logInfo.DOUBLETOKEN.ACCESSTOKEN.TOKENCONTENT);
                localStorage.accessToken = logInfo.DOUBLETOKEN.ACCESSTOKEN.TOKENCONTENT;
                let end = new Date(
                    logInfo.DOUBLETOKEN.ACCESSTOKEN.EXPIRES
                ).getTime();
                let start = new Date().getTime();
                localStorage.validity = end - start;
            }
            // 记住密码操作
            // 获得用户的功能权限
            let modulePowerList = await this.GetFuncTreeFirst({
            });
            let modulePower = JSON.stringify(modulePowerList);
            this.SET_USERNAME(logInfo.NAME);
            this.SET_USERREALNAME(logInfo.REALNAME);
            this.SET_USERID(logInfo.ID);
            this.SET_DEPARTMENTID(logInfo.DWID);
            this.SET_MODULEPOWER(modulePower);
            this.SET_ROLEID(logInfo.ROLEIDS);
            localStorage.userRealName = logInfo.REALNAME;
            localStorage.userId = logInfo.ID;
            localStorage.departmentId = logInfo.DWID;
            localStorage.modulePower = modulePower;
            localStorage.roleId = logInfo.ROLEIDS;
            this.getSecondToken();

            let exp = new Date();
            if (this.rememberPWD) {
                let Days = 30;
                exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
            } else {
                exp.setTime(new Date().getTime() - 1);
            }
            document.cookie = `pygcUserName=${this.login.userName
                };expires=${exp.toGMTString()}`;
            document.cookie = `pygcPassword=${this.login.passWord
                };expires=${exp.toGMTString()}`;

            // 记住密码
            if (!this.rememberPWD) {
                this.login.userName = "";
                this.login.passWord = "";
            }
            this.rememberPWD = false;

            if (
                logInfo.DOUBLETOKEN &&
                logInfo.DOUBLETOKEN.REFRESHTOKEN &&
                logInfo.DOUBLETOKEN.REFRESHTOKEN.TOKENCONTENT
            ) {
                this.SET_REFRESHTOKEN(
                    logInfo.DOUBLETOKEN.REFRESHTOKEN.TOKENCONTENT
                );
                localStorage.refreshToken = logInfo.DOUBLETOKEN.REFRESHTOKEN.TOKENCONTENT
            }

            let path = ""
            let powerList =
                (this.modulePower && JSON.parse(this.modulePower)) ||
                (localStorage.modulePower &&
                    JSON.parse(localStorage.modulePower)) ||
                [];
            let obj = {}
            switch (this.system) {
                case "system1": //监测系统
                    path = "/home"
                    break;
                case "system2": //林业局
                    this.systems = [];
                    this.systemList2.forEach((v) => {
                        let item = powerList.find((val) => val.id === v.ID);
                        if (item) {
                            this.systems.push(v);
                        }
                    });
                    obj = this.systems[0]
                    path = obj.routeName
                    break;
                case "system3": //茶特中心
                    this.systems = [];
                    this.systemList3.forEach((v) => {
                        let item = powerList.find((val) => val.id === v.ID);
                        if (item) {
                            this.systems.push(v);
                        }
                    });
                    obj = this.systems[0]
                    path = obj.routeName
                    break;
            }
            this.$router.push({
                path: path,
            });
        },
        getPassword() {
            const cookies = document.cookie;
            let cookieMap = {};
            cookies.split(";").forEach((cookie) => {
                let kv = cookie.trim().split("=");
                cookieMap[kv[0]] = kv[1];
            });
            this.login.userName = cookieMap.pygcUserName || "";
            this.login.passWord = cookieMap.pygcPassword || "";
            if (this.login.userName && this.login.passWord) {
                this.rememberPWD = true;
            }
        },
        forgetPassword() {
            this.$parent.$refs.forgetPassword.dialogData.dialog = true;
        },
        resetData() {
            clearInterval(this.getCodeCountDownTimer);
            this.getCodeBtnTxt = "获取手机验证码";
            this.dialogForget.userName = "";
            this.dialogForget.phoneNumber = "";
            this.dialogForget.repeatPassword = "";
            this.dialogForget.code = "";
            this.dialogForget.password = "";
        },
        // url传参免登录
        loginByUrl() {
            // 获得token
            let token = this.getQueryString("token");
            if (token) {
                localStorage.accessToken = token;
                this.SET_ACCESSTOKEN(token);
                // 获得刷新token
                let refreshToken = this.getQueryString("refreshToken");
                if (refreshToken) {
                    localStorage.refreshToken = refreshToken;
                    this.SET_REFRESHTOKEN(refreshToken);
                }
                this.getMyToken();
                this.getSecondToken();
            }
        },
        getQueryString(name) {
            let url = window.location.href;
            let urlList = url.split("?");
            let urlvalue = `?${urlList[urlList.length - 1]}`;
            var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
            var r = urlvalue.substr(1).match(reg);
            if (r != null) {
                return unescape(r[2]);
            }
            return null;
        },
        // 获得子系统token
        async getMyToken() {
            let strings = localStorage.accessToken.split("."); //截取token，获取载体
            //解析，需要吧‘_’,'-'进行转换否则会无法解析
            var userinfo = JSON.parse(decodeURIComponent(escape(window.atob(strings[1].replace(/-/g, "+").replace(/_/g, "/")))));
            if (userinfo.realname) {
                this.SET_USERREALNAME(userinfo.realname);
                localStorage.userRealName = userinfo.realname;
            }
            if (userinfo.userid) {
                this.SET_USERID(userinfo.userid);
                localStorage.userId = userinfo.userid;
            }
            if (userinfo.role) {
                this.SET_ROLEID(userinfo.role);
                localStorage.roleId = userinfo.role;
            }
            let modulePowerList = await this.GetFuncTreeFirst({
            });
            let modulePower = JSON.stringify(modulePowerList);
            this.SET_MODULEPOWER(modulePower);
            localStorage.modulePower = modulePower;
            this.$router.push({
                path: "/home",
            });
        },
        async getSecondToken() {
            let res = await this.getSysToken({
                sign: "1"
            });
            if (res.ISSUCCESS) {
                let result = res.RESULTVALUE;
                this.SET_SYS_ACCESSTOKEN('bearer ' + result.access_token);
                this.SET_SYS_REFRESHTOKEN(result.refresh_token);
                localStorage.sysValidity = Number(result.expires_in) * 1000;
                this.SET_REFRESHTIMER(new Date().getTime());
            } else {
                console.log('Token获取失败');
            }
        },
        getTabActiveNum(index) {
            this.tabActiveNum = index
        }
    },
};