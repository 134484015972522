<template>
    <el-form
        ref="login"
        status-icon
        class="loginForm"
        :model="login"
        label-width="0"
        enctype="multipart/form-data"
    >
        <div class="enterCtn">
            <div class="logoPart">
                <img src="@image/login/logo.png" class="icon" />
            </div>
            <el-form-item label prop="userName">
                <div class="enterItem flex-y-center">
                    <img src="@image/login/login-user.png" class="icon" />
                    <el-input
                        v-model="login.userName"
                        clearable
                        class="inputItem"
                        placeholder="输入用户名"
                        size="small"
                    ></el-input>
                </div>
            </el-form-item>
            <el-form-item label prop="passWord">
                <div class="enterItem flex-y-center">
                    <img src="@image/login/login-pwd.png" class="icon" />
                    <el-input
                        v-model="login.passWord"
                        clearable
                        class="inputItem"
                        placeholder="输入密码"
                        type="password"
                        size="small"
                    ></el-input>
                </div>
            </el-form-item>
            <el-form-item label prop="checkCode">
                <div class="enterItem flex-between-y-center">
                    <div class="flex-y-center">
                        <img src="@image/login/login-code.png" class="icon" />
                        <el-input
                            v-model="login.checkCode"
                            clearable
                            class="inputItem"
                            placeholder="输入验证码"
                            @keyup.enter.native="() => loginUp()"
                            size="small"
                        ></el-input>
                    </div>
                    <LoginRandomCode ref="loginNeedCode" @changed="changed" />
                </div>
            </el-form-item>
        </div>
        <div class="loginChoice flex-between-y-center">
            <el-checkbox v-model="rememberPWD" class="choiceItem">
                记住密码
            </el-checkbox>
            <div class="choiceItem pointer" @click="forgetPassword">
                忘记密码
            </div>
        </div>
        <div class="loginBtn pointer" @click="loginUp()">登 录</div>
    </el-form>
</template>
<script>
import mixin from "./login_form_mixin.js";
import LoginRandomCode from "./login_randomcode.vue";
export default {
    name: "loginForm",
    components: {
        LoginRandomCode,
    },
    mixins: [mixin],
    data() {
        return {};
    },
};
</script>
<style lang="scss" scoped></style>
