<template>
    <div class="codeInner">
        <img src="@image/login/codeBg.png" v-if="hoverState" class="codeBg" />
        <div class="wrapCode">
            <div class="qrPart">
                <!-- 扫码登录的二维码 -->
                <vue-qr
                    :size="200"
                    :text="uuid"
                    :dotScale="1"
                    :margin="4"
                ></vue-qr>
                <!-- 下载app的二维码 -->
                <img
                    src="@image/login/loadApp.png"
                    class="loadApp"
                    v-if="hoverState"
                />
            </div>
        </div>
        <div
            class="tipPart"
            @mouseenter="hoverState = true"
            @mouseleave="hoverState = false"
        >
            <img
                :src="
                    hoverState
                        ? require('@image/login/loadActive.png')
                        : require('@image/login/loadDefault.png')
                "
                class="img"
            />
            <span class="pointer desc">下载景迈APP</span>
        </div>
    </div>
</template>
<script>
import VueQr from "vue-qr";
import { mapActions, mapMutations, mapGetters } from "vuex";
import * as TYPES from "@store/modules/mutation-type.js";
// 消息推送
import "@libs/mySignalr.js";
export default {
    name: "",
    components: {
        VueQr,
    },
    data() {
        return {
            uuid: "",
            hoverState: false,
            systemList2: [
                {
                    id: "10",
                    name: "工作台面",
                    src: require("@image/header/icon/gztm.png"),
                    routeName: "basicdata",
                },
                {
                    id: "20",
                    name: "巡查管理",
                    src: require("@image/header/icon/xcgl.png"),
                    routeName: "monitoringcloud",
                },
                {
                    id: "93",
                    name: "数据管理",
                    src: require("@image/header/icon/sjgl.png"),
                    // routeName: "data_admin",
                },
                {
                    id: "94",
                    name: "报告管理",
                    src: require("@image/header/icon/sjgl.png"),
                    routeName: "patrolReport",
                },
            ],
            systemList3: [
                {
                    id: "10",
                    name: "工作台面",
                    src: require("@image/header/icon/gztm.png"),
                    routeName: "analysis-assess",
                },
                {
                    id: "93",
                    name: "数据管理",
                    src: require("@image/header/icon/sjgl.png"),
                    // routeName: "data_admin",
                },
            ],
        };
    },
    computed: {
        ...mapGetters(["system"]),
    },
    methods: {
        ...mapMutations({
            SET_DATAGETXZC: TYPES.SET_DATAGETXZC,
            SET_DATAGETCMXZ: TYPES.SET_DATAGETCMXZ,
            SET_DATAGEJMBH: TYPES.SET_DATAGEJMBH,
            SET_DATAGEJMLX: TYPES.SET_DATAGEJMLX,
            SET_DATAGEDCYXM: TYPES.SET_DATAGEDCYXM,
            SET_DATAGETSJZTDCY: TYPES.SET_DATAGETSJZTDCY,
            SET_DATAGETSJZTCCY: TYPES.SET_DATAGETSJZTCCY,
            SET_DATAPAGEINDEX: TYPES.SET_DATAPAGEINDEX,
            SET_DATAGETZTACTIVENUM: TYPES.SET_DATAGETZTACTIVENUM,
            SET_USERNAME: TYPES.SET_USERNAME,
            SET_USERREALNAME: TYPES.SET_USERREALNAME,
            SET_USERID: TYPES.SET_USERID,
            SET_DEPARTMENTID: TYPES.SET_DEPARTMENTID,
            SET_MODULEPOWER: TYPES.SET_MODULEPOWER,
            SET_ROLEID: TYPES.SET_ROLEID,
            SET_ACCESSTOKEN: TYPES.SET_ACCESSTOKEN,
            SET_REFRESHTOKEN: TYPES.SET_REFRESHTOKEN,
            SET_SYS_ACCESSTOKEN: TYPES.SET_SYS_ACCESSTOKEN,
            SET_SYS_REFRESHTOKEN: TYPES.SET_SYS_REFRESHTOKEN,
        }),
        ...mapActions([
            "loginNeedCode",
            "getFuncTree",
            "LoginNoVCode",
            "checkUserName",
            "GetFuncTreeFirst",
            "getSysToken",
        ]),
        // 开启扫码登录  Account
        startScanCodeLogin() {
            this.uuid = this.common.getGuid();
            console.log(
                "%c 🌮 this.uuid: ",
                "font-size:20px;background-color: #E41A6A;color:#fff;",
                this.uuid
            );
            window.signalr_connection.on(`${this.uuid}`, (data) => {
                console.log(
                    "%c 🍞 data: ",
                    "font-size:20px;background-color: #6EC1C2;color:#fff;",
                    data
                );
                this.scanCodeLoginAction(
                    JSON.parse(data).NAME,
                    JSON.parse(data).PASSWORD
                );
            });
        },
        async scanCodeLoginAction(userName, passWord) {
            let result = await this.LoginNoVCode({
                userName: userName,
                pwd: passWord,
            });
            if (result.ISSUCCESS) {
                this.saveData(result.RESULTVALUE.RESULTVALUE);
            } else {
                this.login.checkCode = "";
                this.common.showMsg(result.RESULTDESCRIPTION, "warning");
            }
        },
        // 储存数据 获得用户的功能权限
        async saveData(logInfo) {
            if (
                logInfo.DOUBLETOKEN &&
                logInfo.DOUBLETOKEN.ACCESSTOKEN &&
                logInfo.DOUBLETOKEN.ACCESSTOKEN.TOKENCONTENT
            ) {
                this.SET_ACCESSTOKEN(
                    logInfo.DOUBLETOKEN.ACCESSTOKEN.TOKENCONTENT
                );
                localStorage.accessToken =
                    logInfo.DOUBLETOKEN.ACCESSTOKEN.TOKENCONTENT;
                let end = new Date(
                    logInfo.DOUBLETOKEN.ACCESSTOKEN.EXPIRES
                ).getTime();
                let start = new Date().getTime();
                localStorage.validity = end - start;
            }
            // 记住密码操作
            // 获得用户的功能权限
            let modulePowerList = await this.GetFuncTreeFirst({});
            let modulePower = JSON.stringify(modulePowerList);
            this.SET_USERNAME(logInfo.NAME);
            this.SET_USERREALNAME(logInfo.REALNAME);
            this.SET_USERID(logInfo.ID);
            this.SET_DEPARTMENTID(logInfo.DWID);
            this.SET_MODULEPOWER(modulePower);
            this.SET_ROLEID(logInfo.ROLEIDS);
            localStorage.userRealName = logInfo.REALNAME;
            localStorage.userId = logInfo.ID;
            localStorage.departmentId = logInfo.DWID;
            localStorage.modulePower = modulePower;
            localStorage.roleId = logInfo.ROLEIDS;
            this.getSecondToken();
            if (
                logInfo.DOUBLETOKEN &&
                logInfo.DOUBLETOKEN.REFRESHTOKEN &&
                logInfo.DOUBLETOKEN.REFRESHTOKEN.TOKENCONTENT
            ) {
                this.SET_REFRESHTOKEN(
                    logInfo.DOUBLETOKEN.REFRESHTOKEN.TOKENCONTENT
                );
                localStorage.refreshToken =
                    logInfo.DOUBLETOKEN.REFRESHTOKEN.TOKENCONTENT;
            }

            let path = "";
            let powerList =
                (this.modulePower && JSON.parse(this.modulePower)) ||
                (localStorage.modulePower &&
                    JSON.parse(localStorage.modulePower)) ||
                [];
            let obj = {};
            switch (this.system) {
                case "system1": //监测系统
                    path = "/home";
                    break;
                case "system2": //林业局
                    this.systems = [];
                    this.systemList2.forEach((v) => {
                        let item = powerList.find((val) => val.id === v.ID);
                        if (item) {
                            this.systems.push(v);
                        }
                    });
                    obj = this.systems[0];
                    path = obj.routeName;
                    break;
                case "system3": //茶特中心
                    this.systems = [];
                    this.systemList3.forEach((v) => {
                        let item = powerList.find((val) => val.id === v.ID);
                        if (item) {
                            this.systems.push(v);
                        }
                    });
                    obj = this.systems[0];
                    path = obj.routeName;
                    break;
            }
            this.$router.push({
                path: path,
            });
        },
        async getSecondToken() {
            let res = await this.getSysToken({
                sign: "1",
            });
            if (res.ISSUCCESS) {
                let result = res.RESULTVALUE;
                this.SET_SYS_ACCESSTOKEN("bearer " + result.access_token);
                this.SET_SYS_REFRESHTOKEN(result.refresh_token);
                localStorage.sysValidity = Number(result.expires_in) * 1000;
            } else {
                console.log("Token获取失败");
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.codeInner {
    width: 100%;
    height: 100%;
    position: relative;
    padding: 60px 30px 30px 30px;
    .codeBg {
        position: absolute;
        top: 60px;
        left: 50%;
        transform: translateX(-50%);
    }
    .wrapCode {
        width: 100%;
        height: 244px;
        .qrPart {
            width: 200px;
            height: 200px;
            margin: 0 auto;
            padding-top: 14px;
            position: relative;
            .loadApp {
                position: absolute;
                left: 0;
                top: 14px;
                z-index: 10;
                width: 200px;
                height: 200px;
            }
        }
    }
    .tipPart {
        text-align: center;
        margin-top: 10px;
        .img {
            width: 21px;
            height: 23px;
            margin-right: 10px;
        }
        .desc {
            font-size: 18px;
            color: #5f5f5f;
            &:hover {
                color: #20b595;
            }
        }
    }
}
</style>
