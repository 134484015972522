<template>
    <div id="loginPart2" class="loginPart">
        <!-- 登陆 -->
        <img src="@image/login/login2/title.png" class="title" />
        <img src="@image/login/login2/topBg.png" class="topBg" />
        <img src="@image/login/login2/bottomBg.png" class="bottomBg" />
        <img src="@image/login/login2/leftBg.png" class="leftBg" />

        <div class="warpCtn">
            <img
                :src="
                    activeNum == 1
                        ? require('@image/login/login2/web.png')
                        : require('@image/login/login2/code.png')
                "
                class="code pointer"
                @click="getTabActiveNum()"
            />

            <loginForm ref="loginForm" v-if="activeNum == 0"></loginForm>
            <!-- 二维码登录 -->
            <div class="codePart" v-else>
                <qrCode ref="qrCode"></qrCode>
            </div>
        </div>

        <div class="bottomDesc">
            <span style="margin-right: 20px"
                >建设单位：普洱景迈山古茶林保护管理局</span
            >
            <span>技术支持：国信司南（北京）地理信息技术有限公司</span>
        </div>
        <forgetPassword ref="forgetPassword"></forgetPassword>
    </div>
</template>
<script>
import loginForm from "../comp/login_form2.vue";
import forgetPassword from "../comp/forget_password.vue";
import qrCode from "../login_type/qr_code.vue";
export default {
    name: "login2",
    components: {
        loginForm,
        forgetPassword,
        qrCode,
    },
    data() {
        return {
            activeNum: 0,
        };
    },
    methods: {
        getTabActiveNum() {
            if (this.activeNum == 0) {
                this.activeNum = 1;
            } else {
                this.activeNum = 0;
            }
            if (this.activeNum == 1) {
                this.$nextTick(() => {
                    this.$refs.qrCode.startScanCodeLogin();
                });
            }
        },
    },
};
</script>
<style lang="scss">
#loginPart2.loginPart {
    width: 100%;
    height: 100%;
    position: relative;
    .title {
        margin: 112px 0 0 77px;
        width: 42%;
    }
    .warpCtn {
        position: absolute;
        top: 50%;
        right: 10%;
        transform: translateY(-50%);
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        padding: 1% 2% 1.2%;
        border-radius: 4px;
        width: 436px;
        height: 560px;
        .loginForm {
            .enterCtn {
                margin-top: 30px;
                .logoPart {
                    text-align: center;
                    margin-bottom: 14px;
                    .icon {
                        width: 151px;
                        height: 172px;
                    }
                }
                .enterItem {
                    width: 100%;
                    height: 44px;
                    padding-left: 24px;
                    border: 2px solid rgba(243, 243, 243, 1);
                    position: relative;
                    background: #fff;
                    &:hover {
                        &::before {
                            position: absolute;
                            left: 0;
                            top: 0;
                            content: "";
                            width: 5px;
                            height: 100%;
                            background: rgba(24, 109, 112, 1);
                            border-radius: 5px;
                        }
                    }
                    .icon {
                        width: 15px;
                        height: 16px;
                        margin-right: 10px;
                    }
                    .inputItem {
                        width: 100%;
                        height: 41px;
                    }
                }
                .el-form-item__error {
                    top: 40px;
                }
            }
        }
        .code {
            position: absolute;
            right: 0;
            top: 0;
            z-index: 10;
        }

        .codePart {
            width: 100%;
            height: 100%;
            padding-top: 30px;
        }
    }

    .loginChoice {
        margin-top: 40px;
        .choiceItem {
            color: #333;
        }

        .codeIcon {
            margin-left: 10px;
            width: 21px;
            height: 21px;
            background: url("~@image/login/code.png");
            background-size: 100% 100%;
        }
        .codeIconLoad {
            width: 13px;
            height: 21px;
            background: url("~@image/login/loadApp.png");
            background-size: 100% 100%;
        }
    }

    .loginBtn {
        background: #1da18e;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        color: #fff;
        line-height: 41px;
        border-radius: 5px;
        margin-top: 24px;
    }

    .bottomDesc {
        position: absolute;
        bottom: 4%;
        left: 50%;
        transform: translateX(-50%);
        font-size: 16px;
        color: #fff;
    }

    .topBg {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 127px;
    }
    .bottomBg {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
    }
    .leftBg {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 75%;
        width: 75%;
        height: 86%;
        z-index: -1;
    }
}

@media screen and (max-width: 1500px) {
    #loginPart2.loginPart {
        .title {
            margin: 62px 0 0 77px;
        }
        .bottomDesc {
            bottom: 3%;
        }
    }
}
</style>
