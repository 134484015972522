<template>
    <div class="tabPart flex">
        <div
            v-for="(item, index) in listAry"
            :key="index"
            class="tabItem pointer"
            :class="[activeNum == index ? 'tabItemActive' : '']"
            @click="getTabItem(index)"
        >
            <div>
                {{ item.name }}
            </div>
            <div v-if="activeNum == index" class="line"></div>
        </div>
    </div>
</template>
<script>
export default {
    name: "",

    components: {},
    data() {
        return {
            activeNum: 0,
            listAry: [
                {
                    name: "账号登录",
                },
                {
                    name: "扫码登录",
                },
            ],
        };
    },
    methods: {
        getTabItem(index) {
            this.activeNum = index;
            this.$emit("getTabActiveNum", index);
        },
    },
};
</script>
<style lang="scss" scoped>
.tabPart {
    .tabItem {
        margin-right: 40px;
        color: #5f5f5f;
        font-size: 18px;
        .line {
            width: 40px;
            height: 3px;
            background: #20b595;
            margin: 0 auto;
            border-radius: 4px;
        }
    }
    .tabItemActive {
        color: #20b595;
    }
}
</style>
