<template>
    <div>
        <SnDialog :dialogData="dialogData" @onSubmit="onSubmit">
            <el-form
                ref="dialogForget"
                :model="dialogForget"
                status-icon
                :rules="dialogForgetRules"
                label-width="110px"
            >
                <el-form-item label="用户名" prop="userName">
                    <el-input
                        v-model="dialogForget.userName"
                        clearable
                    ></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="phoneNumber">
                    <div class="flex">
                        <el-input
                            v-model="dialogForget.phoneNumber"
                            type="text"
                            clearable
                            autocomplete="off"
                        ></el-input>
                        <el-button
                            type="primary"
                            :disabled="!disabledGetCodeBtn"
                            :style="{
                                background: '#009943',
                                border: 'none',
                                marginLeft: '5px',
                            }"
                            @click="getCodeFromPhone"
                        >
                            {{getCodeBtnTxt}}
                        </el-button>
                    </div>
                </el-form-item>
                <el-form-item label="验证码" prop="code">
                    <el-input
                        v-model="dialogForget.code"
                        type="text"
                        clearable
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="新密码" prop="passWord">
                    <el-input
                        v-model="dialogForget.passWord"
                        type="password"
                        clearable
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
                <el-form-item label="确认新密码" prop="repeatPassword">
                    <el-input
                        v-model="dialogForget.repeatPassword"
                        type="password"
                        clearable
                        autocomplete="off"
                    ></el-input>
                </el-form-item>
            </el-form>
        </SnDialog>
    </div>
</template>
<script>
import mixin from "./forget_password_mixin";
export default {
    name: 'forgetPassword',
    mixins: [mixin],
    data() {
        return {
        };
    },
};
</script>
<style lang="scss" scoped>

</style>